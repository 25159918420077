import type { SVGProps } from 'react';
const SvgAttachment = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.958 7.52138L9.08794 13.3915C8.91769 13.5559 8.78189 13.7526 8.68846 13.9701C8.59504 14.1876 8.54586 14.4215 8.54381 14.6582C8.54175 14.8949 8.58685 15.1296 8.67648 15.3487C8.76611 15.5677 8.89848 15.7668 9.06585 15.9342C9.23322 16.1015 9.43225 16.2339 9.65133 16.3235C9.8704 16.4131 10.1051 16.4583 10.3418 16.4562C10.5785 16.4541 10.8124 16.405 11.0299 16.3115C11.2474 16.2181 11.4441 16.0823 11.6085 15.9121L17.3253 10.042C17.9747 9.36957 18.3341 8.46899 18.326 7.53421C18.3179 6.59943 17.9429 5.70524 17.2819 5.04422C16.6209 4.3832 15.7267 4.00826 14.7919 4.00013C13.8571 3.99201 12.9565 4.35136 12.2841 5.00079L6.56646 10.87C5.56347 11.873 5 13.2333 5 14.6518C5 16.0702 5.56347 17.4305 6.56646 18.4335C7.56945 19.4365 8.9298 20 10.3482 20C11.7667 20 13.127 19.4365 14.13 18.4335L19.7069 12.8692"
    />
  </svg>
);
export default SvgAttachment;
