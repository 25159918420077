import flatMap from 'lodash/flatMap';
import isObject from 'lodash/isObject';
import { ReverseParams } from 'named-urls';
import { matchPath, To, NavigateOptions } from 'react-router-dom';
import { queryClient } from 'domains/data';
import { asyncRouteMapping } from 'router/async-routes';
import { routes } from 'router/routes';
import { LocationWithQuery } from 'utils/hooks/useNavigation';

export type AsyncRoutePath = keyof typeof asyncRouteMapping;

export type RoutePaths = { [key: string]: string | (() => string) | RoutePaths };

/**
 * Given a nested route object (created with `named-urls`) produces a flat
 * array of all of the path patterns represented in the map. These patterns may
 * include placeholder params (e.g. `:id`). e.g.
 *
 *    const routes = {
 *      foo: '/foo',
 *      bar: include('/bar', {
 *        baz: 'baz/:id
 *      })
 *    }
 *
 * `flattenRoutes(routes)` will produce:
 *
 *    ['/foo', '/bar', '/bar/baz/:id']
 */
const flattenRoutes = (routeMap: RoutePaths): AsyncRoutePath[] => {
  return flatMap(
    Object.keys(routeMap).map(key => {
      if (isObject(routeMap[key])) {
        return flattenRoutes(routeMap[key] as Record<string, RoutePaths>);
      }

      return String(routeMap[key]);
    }),
  );
};

export const allRoutes = flattenRoutes(routes);

/**
 * Given a path, finds the associated pattern that it matches within all of the
 * paths defined in `router/routes`. e.g. with the above example
 *
 *    resolvedPathToPattern('/bar/baz/3') => '/bar/baz/:id'
 */
export const resolvedPathToPattern = (to: To): AsyncRoutePath | undefined => {
  const path = typeof to === 'string' ? to : to?.pathname;

  if (path) {
    return allRoutes.find(route => matchPath(route, path));
  }

  return undefined;
};

export const preload = async (
  path: AsyncRoutePath | undefined,
  routeParams: ReverseParams | undefined,
  state: NavigateOptions['state'],
): Promise<void> => {
  if (path && path in asyncRouteMapping) {
    const component = await asyncRouteMapping[path].preload();

    if (component && component.fetchData !== undefined) {
      await component.fetchData(queryClient, routeParams, state);
    }
  }

  return undefined;
};

export const url = (location: LocationWithQuery<unknown>): string => {
  return `${location.pathname}${location.search}`;
};
