import * as uuid from 'uuid';

export * from './useFlashMessages';

export type FlashVariant = 'success' | 'error' | 'warning' | 'info';

export type FlashMessage = {
  id: string;
  variant: FlashVariant;
  message: string;
  scope: string | undefined;
  key?: string;
};

export type FlashMessageData = Omit<FlashMessage, 'id'>;

export type LocationStateWithFlash = {
  flash?: FlashMessage[];
};

export const createFlash = (variant: FlashVariant, message: string, scope?: string, key?: string): FlashMessage => {
  return {
    id: uuid.v4(),
    variant,
    message,
    scope,
    key,
  };
};

export const hasFlashState = (state: unknown | LocationStateWithFlash): state is LocationStateWithFlash => {
  return (state as LocationStateWithFlash)?.flash !== undefined;
};

export const filterFlashMessages = (scope: string | undefined, flashMessages: FlashMessage[]): FlashMessage[] => {
  return flashMessages.filter(flash => flash.scope === scope);
};

export const dedupeFlashMessages = (flashMessages: FlashMessage[]): FlashMessage[] => {
  const seenKeys = new Set();

  return flashMessages.filter(flash => {
    if (flash.key && seenKeys.has(flash.key)) {
      return false;
    }

    seenKeys.add(flash.key);

    return true;
  });
};
