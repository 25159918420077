import type { SVGProps } from 'react';
const SvgTime = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      d="M12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V12C11.0001 12.2652 11.1055 12.5195 11.293 12.707L14.121 15.536C14.2139 15.6289 14.3242 15.7026 14.4456 15.7529C14.567 15.8032 14.6971 15.8291 14.8285 15.8291C14.9599 15.8291 15.09 15.8032 15.2114 15.7529C15.3328 15.7026 15.4431 15.6289 15.536 15.536C15.6289 15.4431 15.7026 15.3328 15.7529 15.2114C15.8032 15.09 15.8291 14.9599 15.8291 14.8285C15.8291 14.6971 15.8032 14.567 15.7529 14.4456C15.7026 14.3242 15.6289 14.2139 15.536 14.121L13 11.586V8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTime;
