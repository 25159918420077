import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { NavBarRoute } from 'components/Layout/AuthedLayout/AuthedHeader/NavigationBar/types';
import Menu from 'components/Menu';
import { LANGUAGES } from 'domains/language';
import { useBreakpoint } from 'utils/hooks/useBreakpoint';

type Props = {
  navigationItems?: NavBarRoute[];
  className?: string;
};

export const HamburgerMenu: React.FC<Props> = ({ navigationItems, className }) => {
  const isMobile = useBreakpoint({
    max: '2xl',
  });

  const {
    i18n: { language, changeLanguage },
    t,
  } = useTranslation();

  if (!isMobile) {
    return null;
  }

  const hasNavigation = isArray(navigationItems);

  const onSwitchLanguage = (langId: string) => {
    changeLanguage(langId);
  };

  return (
    <Popover className={classNames('', className)}>
      {({ close, open }) => {
        return (
          <>
            <Popover.Button>
              <Button
                variant="ghost"
                as="div"
                size="sm"
                className={classNames('w-40 h-40', {
                  'bg-black-12%': open,
                })}
                iconOnly
              >
                <Icon name={open ? 'Close' : 'Bars'} className="h-24 w-24" />
              </Button>
            </Popover.Button>
            <Popover.Panel className="absolute left-0 px-8 z-50 max-w-[560px] w-full mt-8">
              <Menu>
                {hasNavigation && (
                  <Menu.NavSection>
                    {navigationItems.map(({ label, route }) => (
                      <Menu.NavItem to={route} key={route} onClick={close}>
                        {t(label)}
                      </Menu.NavItem>
                    ))}
                  </Menu.NavSection>
                )}
                <Menu.SectionTitle>{t('language.sectionTitle')}</Menu.SectionTitle>
                <Menu.SelectSection
                  defaultValue={language}
                  onChange={(value: string) => {
                    onSwitchLanguage(value);
                  }}
                >
                  {LANGUAGES.map(({ value, label }) => (
                    <Menu.SelectItem value={value} key={value}>
                      {label}
                    </Menu.SelectItem>
                  ))}
                </Menu.SelectSection>
              </Menu>
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
};
