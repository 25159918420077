import debounce from 'lodash/debounce';
import NProgress from 'nprogress';

NProgress.configure({ showSpinner: false });

let timer: NodeJS.Timeout;
let loading = false;
let activeRequests = 0;

export const SHOW_DELAY = 350;
export const HIDE_DELAY = 200;

const hideLoader = debounce(
  () => {
    loading = false;

    clearTimeout(timer);
    NProgress.done();
  },
  HIDE_DELAY,
  { leading: false, trailing: true },
);

export const start = (incrementCount = false): void => {
  if (incrementCount) {
    activeRequests += 1;
  }

  if (loading) {
    return;
  }

  loading = true;

  timer = setTimeout(() => {
    NProgress.start();
  }, SHOW_DELAY);
};

export const stop = (decrementCount = false): void => {
  if (decrementCount) {
    activeRequests -= 1;
  }

  if (activeRequests > 0) {
    return;
  }

  hideLoader();
};
