import type { SVGProps } from 'react';
const SvgRight = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_709_16982)">
      <path d="M9.7925 8.5375L13.6725 12.4175L9.7925 16.2975C9.4025 16.6875 9.4025 17.3175 9.7925 17.7075C10.1825 18.0975 10.8125 18.0975 11.2025 17.7075L15.7925 13.1175C16.1825 12.7275 16.1825 12.0975 15.7925 11.7075L11.2025 7.1175C10.8125 6.7275 10.1825 6.7275 9.7925 7.1175C9.4125 7.5075 9.4025 8.1475 9.7925 8.5375Z" />
    </g>
    <defs>
      <clipPath id="clip0_709_16982">
        <rect width={24} height={24} />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRight;
