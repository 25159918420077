import { Outlet } from 'react-router-dom';
import { ComponentPropsWithoutRef } from 'react';
import { FlashWrapper } from 'components/FlashMessages';
import { SkipLinks } from 'components/SkipLinks';
import { WormholeExit } from 'components/Wormhole';
import { BasicHeader } from '../BasicHeader';

type Props = {
  children?: React.ReactNode;
  flashWrapper?: boolean;
  showLogin?: boolean;
} & ComponentPropsWithoutRef<typeof BasicHeader>;

export const BasicLayout = ({ children = <Outlet />, flashWrapper = true, ...rest }: Props): JSX.Element => {
  return (
    <>
      <SkipLinks />
      <BasicHeader {...rest} />
      <WormholeExit loc="header" />
      <main id="maincontent" tabIndex={-1} className="flex flex-col flex-grow bg-cream-50 max-w-[100vw]">
        {flashWrapper && <FlashWrapper />}
        {children}
      </main>
    </>
  );
};
