import cx from 'classnames';
import React, { Suspense, useRef, useEffect, useState, useCallback } from 'react';
import { GlobalProgress } from 'components/GlobalProgress';
import { Loading } from 'components/AppLoader/Loading';
import { Delayed } from 'components/AppLoader/Delayed';

const HIDE_DELAY_FAST = 50;
const HIDE_DELAY = 200;

export const AppLoader = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [visible, setVisible] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  const showLoader = useCallback(() => {
    setInitialized(true);

    if (timerRef.current !== undefined) {
      clearTimeout(timerRef.current);
    }

    setVisible(true);
  }, [timerRef]);

  const hideLoader = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setVisible(false);
    }, HIDE_DELAY);
  }, [timerRef]);

  return (
    <>
      <Loading
        className={cx('transition-all duration-100 z-10', {
          'opacity-0 pointer-events-none duration-500': !visible,
        })}
        iconClassName={cx('transition-all duration-100', {
          'scale-90 duration-500': !visible,
        })}
      />
      <Suspense
        fallback={
          <GlobalProgress>
            <Delayed delay={initialized ? HIDE_DELAY : HIDE_DELAY_FAST}>
              <ToggleAppLoader onMount={showLoader} onUnmount={hideLoader} />
            </Delayed>
          </GlobalProgress>
        }
      >
        {children}
      </Suspense>
    </>
  );
};

export const ToggleAppLoader = ({ onMount, onUnmount }: { onMount: () => void; onUnmount: () => void }): null => {
  useEffect(() => {
    onMount();
    return onUnmount;
  }, [onMount, onUnmount]);

  return null;
};
