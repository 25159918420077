import { Button, ButtonProps } from 'components/Button';
import { Link } from 'components/Link';

export type Props = Omit<ButtonProps<typeof Link>, 'disabled' | 'loading' | 'loadingText'>;

export const ButtonLink = ({ children, className, ...props }: Props): JSX.Element => {
  return (
    <Button as={Link} {...props} includeDefaultClasses={false} className={className} disabled={false} loading={false}>
      {children}
    </Button>
  );
};
