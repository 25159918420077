import { useMedia } from 'react-use';
import breakpoints from 'config/breakpoints.json';

type Breakpoint = keyof typeof breakpoints;
type Props = { min?: Breakpoint; max?: Breakpoint };

export const useBreakpoint = (props: Props): boolean => {
  if (!props.min && !props.max) {
    throw new Error(`Invalid props given to useBreakpoint: ${JSON.stringify(props)}`);
  }

  const min = props.min ? breakpoints[props.min] : 0;
  const max = props.max ? breakpoints[props.max] : 0;

  if (props.min && props.max && min >= max) {
    throw new Error(`Invalid min and max passed to useBreakpoint. Min must be narrower than max.`);
  }

  const matchesMin = useMedia(`(min-width: ${min}px)`);
  const matchesMax = useMedia(`(max-width: ${max - 1}px)`);

  if (props.min && props.max) {
    return matchesMin && matchesMax;
  }

  if (props.min) {
    return matchesMin;
  }

  if (props.max) {
    return matchesMax;
  }

  /* istanbul ignore next */
  return false as never;
};
