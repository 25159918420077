import React, { useContext } from 'react';
import { CognitoUser } from 'domains/auth/AWSCognitoWrapper/types';

type LoggedContext = {
  isLoggedIn: true;
  user: CognitoUser;
  signOut: () => void;
};

type LoggedOutContext = {
  isLoggedIn: false;
  onLogin: (u: CognitoUser) => void;
};

export type CognitoUserContext = LoggedContext | LoggedOutContext;

export const CognitoUserCtx = React.createContext<CognitoUserContext>({} as CognitoUserContext);

export const useCognitoUserContext = () => {
  const ctx = useContext(CognitoUserCtx);

  if (!ctx) {
    throw new Error(`You must wrap this component in a CognitoUserContext provider`);
  }

  return ctx;
};

export const useCognitoUserContextWithUser = () => {
  const ctx = useCognitoUserContext();

  if (!ctx.isLoggedIn) {
    throw new Error(`You can only use this in a scope where a user must be logged in (within authed layout)`);
  }

  return ctx as LoggedContext;
};
