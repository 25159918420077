import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { Props as IconButtonProps } from 'components/IconButton';
import { Icon } from 'components/Icon';
import { FlashVariant } from 'domains/flash-messages';
import { CloseButton } from './CloseButton';

export type Props = {
  variant: FlashVariant;
  children?: React.ReactNode;
  testId?: string;
  onDismiss?: () => void;
  className?: string;
};

const iconMapping: Record<FlashVariant, IconButtonProps<'button'>['name']> = {
  success: 'CheckCircle',
  error: 'Error',
  info: 'InfoCircle',
  warning: 'Warning',
};

const colorMapping: Record<FlashVariant, [string, string, string]> = {
  success: [
    'shadow-md',
    'text-success-800',
    'hover:text-success-800 focus:text-success-800 focus-visible:ring-success-500/30 focus-visible:ring-offset-success-50',
  ],
  error: [
    'shadow-md',
    'text-error-900',
    'hover:text-error-900 focus:text-error-900 focus-visible:ring-error-500/30 focus-visible:ring-offset-error-50',
  ],
  info: [
    'shadow-md',
    'text-info-800',
    'hover:text-info-800 focus:text-info-800 focus-visible:ring-info-500/30 focus-visible:ring-offset-info-50',
  ],
  warning: [
    'shadow-md',
    'text-orange-700',
    'focus:text-warning-700 focus-visible:ring-warning-500/30 focus-visible:ring-offset-warning-50',
  ],
};

function getAriaRole(variant: FlashVariant) {
  if (variant === 'error') {
    return 'alert';
  }

  return 'status';
}

export const FlashMessage = ({ variant, children, onDismiss = undefined, testId, className }: Props): JSX.Element => {
  const [bgClass, textClass, buttonClass] = colorMapping[variant];

  return (
    <div
      data-testid={testId}
      role={getAriaRole(variant)}
      className={twMerge(
        cx(bgClass, 'flex min-h-[56px] bg-white items-start rounded-md py-6 font-semibold', {
          'px-16': !onDismiss,
          'pl-16 pr-6': !!onDismiss,
        }),
        className,
      )}
    >
      {iconMapping[variant] && (
        <Icon name={iconMapping[variant]} className={`mr-12 flex-shrink-0 my-10 ${textClass}`} />
      )}

      <span className="font-semibold grow my-[11px] sm:my-10 text-sm sm:text-md">{children}</span>

      {onDismiss && <CloseButton onClick={onDismiss} className={buttonClass} />}
    </div>
  );
};
