import cx from 'classnames';
import { useEffect, useState } from 'react';

export const Delayed = ({
  delay,
  children,
}: {
  delay: number;
  children: JSX.Element | (({ animationClasses }: { animationClasses: string }) => JSX.Element);
}): JSX.Element | null => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  if (typeof children === 'function') {
    return children({
      animationClasses: cx('transition-all duration-200', {
        'opacity-0 scale-90': !ready,
      }),
    });
  }

  return ready ? children : null;
};
