import cx from 'classnames';
import { Icon } from 'components/Icon';

export const Loading = ({ className, iconClassName }: { className?: string; iconClassName?: string }): JSX.Element => {
  return (
    <div
      role="status"
      aria-label="loading"
      data-testid="app-loader"
      className={cx(className, 'fixed bg-white inset-0 w-full h-full flex items-center justify-center')}
    >
      <div data-testid="index-animate-spin-step" className={iconClassName}>
        <Icon name="Spinner" className="animate-spin-step text-primary-500 w-32 h-32" />
      </div>
    </div>
  );
};
