import get from 'lodash/get';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlashMessageScope } from './useFlashMessageScope';
import { createFlash, dedupeFlashMessages, FlashMessage, FlashVariant } from '.';

export type UseFlashMessagesValue = {
  flashMessages: FlashMessage[];
  createFlash: typeof createFlash;
  showFlash: (
    messages: Array<{ variant: FlashVariant; message: string; key?: string }>,
    options?: { append?: boolean },
  ) => string[];
  clearFlash: (ids?: string[]) => void;
};

/**
 * Hook that provides convenience methods for working with flash messages.
 *
 * @NOTE(shawk): the implementations for `showFlash` and `clearFlash`
 * intentially avoid depending on `location` since they result in calls to
 * `navigate` which will change `location`. Instead, they directly read
 * the most up-to-date location pathname and state (`window.location` and
 * `window.history.state` respectively). This avoid some effects that depend
 * on them from running too frequently.
 */
export const useFlashMessages = (): UseFlashMessagesValue => {
  const navigate = useNavigate();
  const location = useLocation();
  const scope = useFlashMessageScope();

  const flashMessages: FlashMessage[] = get(location, 'state.flash', []);

  const showFlash: UseFlashMessagesValue['showFlash'] = useCallback(
    (messages): string[] => {
      const newFlashMessages = messages.map(({ variant, message, key }) => {
        return createFlash(variant, message, scope, key);
      });

      const { pathname, search } = window.location;
      const { state } = window.history;

      navigate(`${pathname}${search}`, {
        replace: true,
        state: {
          ...((state?.usr as Record<string, unknown>) || {}),
          flash: dedupeFlashMessages(newFlashMessages.concat(state?.usr?.flash || [])),
        },
      });

      return newFlashMessages.map(flash => flash.id);
    },
    [navigate, scope],
  );

  const clearFlash: UseFlashMessagesValue['clearFlash'] = useCallback(
    idsToRemove => {
      const { pathname, search } = window.location;
      const { state } = window.history;

      navigate(`${pathname}${search}`, {
        replace: true,
        state: {
          ...((state?.usr as Record<string, unknown>) || {}),
          flash: idsToRemove
            ? (state?.usr?.flash || []).filter((flash: FlashMessage) => !idsToRemove.includes(flash?.id))
            : [],
        },
      });
    },
    [navigate],
  );

  return {
    flashMessages,
    createFlash,
    showFlash,
    clearFlash,
  };
};
