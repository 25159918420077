import { useContext } from 'react';
import {
  UserClientsContext,
  UserClientsCtx,
  UserClientsCtxWithActiveClient,
  UserClientsCtxWithLoggedIn,
} from './context';

export const useUserClients = (): UserClientsCtx => {
  const ctx = useContext(UserClientsContext);

  if (!ctx) {
    throw new Error(
      'useUserClients must be called from a descendant of the <AuthWrapper /> component that supplies the associated context provider',
    );
  }

  return ctx as UserClientsCtx;
};

export const useUserClientsLoggedIn = (): UserClientsCtxWithLoggedIn => {
  const ctx = useUserClients();

  if (!ctx.isLoggedIn) {
    throw new Error('useUserClientsLoggedIn must be called from a scope where a user logged in is guaranteed');
  }

  return ctx as UserClientsCtxWithLoggedIn;
};

export const useUserClientsWithActiveClient = (): UserClientsCtxWithActiveClient => {
  const authCtx = useUserClientsLoggedIn();

  if (authCtx.isProfileSelected === false) {
    throw new Error(
      'useUserClientsWithActiveClient must be called from a scope where a profile is guaranteed to be selected',
    );
  }

  return authCtx as UserClientsCtxWithActiveClient;
};
