import omit from 'lodash/omit';
import React, { useCallback, useContext, useEffect, useState } from 'react';

/**
 * Wormholes are used to transport rendered components from one place in the
 * React tree to another. This is useful if a component in one sub-tree needs
 * to affect the display of something in another sub-tree.
 *
 * They're slightly different from React <Portal />'s since they don't operate
 * on DOM nodes that are outside of the React root.
 */

type WormholeCtx = {
  setTraveller: (loc: string, traveller: JSX.Element) => void;
  getTraveller: (loc: string) => JSX.Element;
  clearTraveller: (loc: string) => void;
};

export const WormholeContext = React.createContext<WormholeCtx | null>(null);

export const useWormholeContext = (): WormholeCtx => {
  const wormholeContext = useContext(WormholeContext);

  /* istanbul ignore if */
  if (!wormholeContext) {
    throw new Error('useWormholeContext must be used in a descendent of WormholeFabric');
  }

  return wormholeContext;
};

export const WormholeFabric = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [travellers, setTravellers] = useState<{ [loc: string]: JSX.Element }>({});

  const setTraveller = useCallback((loc: string, traveller: JSX.Element) => {
    setTravellers(existing => ({ ...existing, [loc]: traveller }));
  }, []);

  const getTraveller = (loc: string) => {
    return travellers[loc];
  };

  const clearTraveller = useCallback((loc: string) => {
    setTravellers(existing => omit(existing, loc));
  }, []);

  return (
    <WormholeContext.Provider
      value={{
        setTraveller,
        getTraveller,
        clearTraveller,
      }}
    >
      {children}
    </WormholeContext.Provider>
  );
};

export const WormholeEntrance = ({ loc, children }: { loc: string; children: JSX.Element }): null => {
  const { setTraveller, clearTraveller } = useWormholeContext();

  useEffect(() => {
    setTraveller(loc, children);

    return () => {
      clearTraveller(loc);
    };
  }, [setTraveller, clearTraveller, loc, children]);

  return null;
};

export const WormholeExit = ({ loc }: { loc: string }): JSX.Element | null => {
  const { getTraveller } = useWormholeContext();

  const traveller = getTraveller(loc);

  return traveller || null;
};
