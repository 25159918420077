import type { SVGProps } from 'react';
const SvgReport = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path d="M11 4C10.7348 4 10.4804 4.10536 10.2929 4.29289C10.1054 4.48043 10 4.73478 10 5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H13C13.2652 6 13.5196 5.89464 13.7071 5.70711C13.8946 5.51957 14 5.26522 14 5C14 4.73478 13.8946 4.48043 13.7071 4.29289C13.5196 4.10536 13.2652 4 13 4H11Z" />
    <path
      fillRule="evenodd"
      d="M6 7C6 6.46957 6.21071 5.96086 6.58579 5.58579C6.96086 5.21071 7.46957 5 8 5C8 5.79565 8.31607 6.55871 8.87868 7.12132C9.44129 7.68393 10.2044 8 11 8H13C13.7956 8 14.5587 7.68393 15.1213 7.12132C15.6839 6.55871 16 5.79565 16 5C16.5304 5 17.0391 5.21071 17.4142 5.58579C17.7893 5.96086 18 6.46957 18 7V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H8C7.46957 20 6.96086 19.7893 6.58579 19.4142C6.21071 19.0391 6 18.5304 6 18V7ZM15.707 12.707C15.8892 12.5184 15.99 12.2658 15.9877 12.0036C15.9854 11.7414 15.8802 11.4906 15.6948 11.3052C15.5094 11.1198 15.2586 11.0146 14.9964 11.0123C14.7342 11.01 14.4816 11.1108 14.293 11.293L11 14.586L9.707 13.293C9.5184 13.1108 9.2658 13.01 9.0036 13.0123C8.7414 13.0146 8.49059 13.1198 8.30518 13.3052C8.11977 13.4906 8.0146 13.7414 8.01233 14.0036C8.01005 14.2658 8.11084 14.5184 8.293 14.707L10.293 16.707C10.4805 16.8945 10.7348 16.9998 11 16.9998C11.2652 16.9998 11.5195 16.8945 11.707 16.707L15.707 12.707V12.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReport;
