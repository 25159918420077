import { useEffect } from 'react';
import { HIDE_DELAY, start, stop } from 'domains/api/global-progress';

type Props = {
  children: React.ReactNode;
};

let timerId: NodeJS.Timeout | null = null;

/**
 * When mounted, this component will start the global NProgress bar animation.
 * When it unmounts, it defers a call to stop the loading animation by a delay.
 */
export const GlobalProgress = ({ children }: Props): JSX.Element => {
  useEffect(() => {
    function finishLoading() {
      if (timerId) {
        clearTimeout(timerId);
        stop(true);
      }
    }

    finishLoading();
    start(true);

    return () => {
      timerId = setTimeout(finishLoading, HIDE_DELAY);
    };
  }, []);

  return <>{children}</>;
};
