import { RadioGroup } from '@headlessui/react';
import clsx from 'classnames';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/Icon';
import { Heading } from 'components/Typography';

type MenuProps = {
  className?: string;
};

type SectionTitleProps = {
  className?: string;
};

type NavSectionProps = {
  className?: string;
};

type NavItemProps = {
  className?: string;
} & NavLinkProps;

/** Note(juan): using Parameters<> to get native Props type is odd but works, need version 1.7.18 of headless ui to use ComponentPropsWithoutRef directly */
type SelectSectionProps = {
  className?: string;
} & Parameters<typeof RadioGroup>[0];

type SelectItemProps = Parameters<typeof RadioGroup.Option>[0];

const NavSection: React.FC<NavSectionProps> = ({ className, children, ...rest }) => (
  <ul className={clsx(className)} {...rest}>
    {children}
  </ul>
);

const NavItem: React.FC<NavItemProps> = ({ className, children, ...rest }) => (
  <NavLink {...rest}>
    {({ isActive }) => (
      <li
        className={clsx(className, 'h-[72px] flex items-center px-24 py-16 ', {
          'cursor-default': isActive,
          'hover:bg-black-4%': !isActive,
        })}
      >
        <Icon
          className={clsx('w-8 mr-8', {
            hidden: !isActive,
          })}
          name="CirclesTarget"
        />
        <div className="grow">
          <Heading level="h7" className="font-semibold">
            {children}
          </Heading>
        </div>
        {!isActive && <Icon name="AngleRight" className="w-24 h-24 justify-self-end" />}
      </li>
    )}
  </NavLink>
);

const SelectSection: React.FC<SelectSectionProps> = ({ className, ...rest }) => <RadioGroup {...rest} />;

const SelectItem: React.FC<SelectItemProps> = ({ className, children, ...rest }) => {
  const { t } = useTranslation();

  return (
    <RadioGroup.Option {...rest} className="cursor-pointer">
      {({ checked }) => (
        <div
          className={clsx(className, 'h-[72px] flex items-center px-24 py-16 hover:bg-black-4%', {
            'bg-purple-50': checked,
          })}
        >
          <Heading level="h7" className="grow font-semibold">
            {children}
          </Heading>
          {checked && (
            <span className="flex items-center gap-16">
              <Heading level="h9" className="text-black-500">
                {t('menuComponent.selected.viewing')}
              </Heading>{' '}
              <Icon name="CheckSlim" className="h-24 w-24 p-4" />
            </span>
          )}
        </div>
      )}
    </RadioGroup.Option>
  );
};

const SectionTitle: React.FC<SectionTitleProps> = ({ className, children, ...rest }) => (
  <Heading level="h9" className={clsx(className, 'font-semibold p-24 pb-8 border-t border-black-200')} {...rest}>
    {children}
  </Heading>
);

type MenuComposition = {
  NavSection: typeof NavSection;
  NavItem: typeof NavItem;
  SelectSection: typeof SelectSection;
  SelectItem: typeof SelectItem;
  SectionTitle: typeof SectionTitle;
};

const Menu: React.FC<MenuProps> & MenuComposition = ({ className, children, ...rest }) => (
  <div className={clsx(className, 'rounded-lg shadow-md bg-white overflow-hidden')} {...rest}>
    {children}
  </div>
);

Menu.NavSection = NavSection;
Menu.NavItem = NavItem;
Menu.SelectSection = SelectSection;
Menu.SelectItem = SelectItem;
Menu.SectionTitle = SectionTitle;

export default Menu;
