import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppErrorBoundary } from 'components/AppErrorBoundary';
import { AppLoader } from 'components/AppLoader';
import { WormholeFabric } from 'components/Wormhole';
import config from 'config';
import { queryClient } from 'domains/data';
import MixPanelProvider from 'utils/hooks/useMixPanel';
import { initializeSentry } from './utils/sentry';

const Router = React.lazy(() => import('router'));

initializeSentry();

export default function App(): JSX.Element {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppErrorBoundary>
          <MixPanelProvider
            token={config.get('mixpanelToken')}
            config={{
              debug: config.get('env') !== 'production',
              ignore_dnt: true,
            }}
          >
            <AppLoader>
              <WormholeFabric>
                <Router />
              </WormholeFabric>
            </AppLoader>
          </MixPanelProvider>
        </AppErrorBoundary>
      </QueryClientProvider>
    </HelmetProvider>
  );
}
