import cx from 'classnames';
import { ComponentProps } from 'react';
import * as Icons from './icons';

export type IconNameType = keyof typeof Icons;

export type Props = {
  name: IconNameType;
  title?: string;
  className?: string;
} & ComponentProps<'svg'>;

export const Icon = ({ name, title, className }: Props): JSX.Element => {
  const SVGIcon = Icons[name];

  return (
    <SVGIcon
      aria-label={title}
      aria-hidden={title ? undefined : 'true'}
      className={cx(className, 'fill-current', {
        'w-24 h-24': !className || !className.match(/\bw-.*/),
      })}
    />
  );
};
