import type { SVGProps } from 'react';
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      d="M6.00432 2.00221C4.94269 2.00221 3.92454 2.42394 3.17386 3.17462C2.42317 3.92531 2.00144 4.94346 2.00144 6.00509C2.00144 7.06672 2.42317 8.08487 3.17386 8.83556C3.92454 9.58624 4.94269 10.008 6.00432 10.008C7.06595 10.008 8.0841 9.58624 8.83479 8.83556C9.58547 8.08487 10.0072 7.06672 10.0072 6.00509C10.0072 4.94346 9.58547 3.92531 8.83479 3.17462C8.0841 2.42394 7.06595 2.00221 6.00432 2.00221ZM1.13543e-07 6.00509C-0.000120476 5.06011 0.222803 4.12845 0.65064 3.28587C1.07848 2.4433 1.69915 1.7136 2.46217 1.15612C3.22519 0.598651 4.10902 0.229143 5.04178 0.0776552C5.97453 -0.073833 6.92987 -0.00302467 7.8301 0.284321C8.73033 0.571667 9.55003 1.06744 10.2225 1.73131C10.895 2.39518 11.4013 3.20841 11.7003 4.10485C11.9992 5.0013 12.0824 5.95565 11.9429 6.89028C11.8035 7.82491 11.4454 8.71344 10.8978 9.4836L15.7183 14.3041C15.9006 14.4928 16.0015 14.7456 15.9992 15.008C15.9969 15.2704 15.8917 15.5214 15.7061 15.7069C15.5206 15.8924 15.2696 15.9977 15.0072 16C14.7448 16.0022 14.492 15.9014 14.3033 15.7191L9.48383 10.8996C8.58554 11.5384 7.52877 11.9175 6.42931 11.9955C5.32984 12.0735 4.23012 11.8474 3.25064 11.3419C2.27116 10.8364 1.44973 10.071 0.876355 9.12969C0.302978 8.18834 -0.000214205 7.10732 1.13543e-07 6.00509Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSearch;
