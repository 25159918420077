import { createContext, useContext, ComponentType, FunctionComponent, ReactNode, useEffect } from 'react';
import mixpanel, { Mixpanel, Config } from 'mixpanel-browser';
import { useUserClients } from 'domains/auth/UserClientsWrapper/useUserClients';

type WithMixPanel = {
  mixpanel: Mixpanel;
};

const MixPanelContext = createContext({} as WithMixPanel);

/**
 * A hooks to get the original mixpanel instance
 * @returns {Mixpanel} the mixpanel instance from mixpanel-browser
 */
const useMixPanel = () => useContext(MixPanelContext);

/**
 * A HOC to provide the mixpanel instance to the wrapped component
 */
function withMixpanel<T>(Component: ComponentType<T>): FunctionComponent<T & WithMixPanel> {
  // eslint-disable-next-line react/display-name
  return (props: T) => <Component {...props} mixpanel={mixpanel} />;
}

/**
 * A component to initialize the mixpanel instance
 * @param {MixpanelConfig} required config for mixpanel
 * @param {ReactNode} children
 *
 */
const MixPanelProvider = ({
  children,
  token = '',
  config = { ip: false },
}: {
  children: ReactNode;
  token?: string;
  config?: Partial<Config>;
}) => {
  if (!token) {
    throw new Error('Mixpanel token is required');
  }

  mixpanel.init(token, config);
  return <MixPanelContext.Provider value={{ mixpanel }}>{children}</MixPanelContext.Provider>;
};

/**
 * A react hook to track mixpanel events
 * If the hooks called with no parameters, it will return a track function to track events
 * Called with parameters, it will track the event immediately
 * @param {string} eventName the name of the event to track (optional)
 * @returns  [ track ] a function to track events programmatically
 */
function useTrack(actionName?: string, properties?: Record<string, unknown>) {
  const userClientsCtx = useUserClients();
  const { mixpanel: instance } = useMixPanel();

  const { isLoggedIn } = userClientsCtx;

  const track = (name: string, props?: Record<string, unknown>) => {
    instance.track(name, {
      ...props,
      clientID: isLoggedIn ? userClientsCtx.activeClientId : undefined,
    });
  };

  useEffect(() => {
    if (userClientsCtx.isLoggedIn && userClientsCtx.activeClientId && actionName)
      track(`${actionName}`, { ...properties });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClientsCtx, actionName]);

  return { track };
}

export { useMixPanel, useTrack, withMixpanel, MixPanelContext };
export default MixPanelProvider;
