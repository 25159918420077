import React from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
// import { applyPolyfills } from 'utils/polyfills';
import config from 'config';
import App from './App';

import '@stripe/stripe-js';
import './i18n';
import 'styles/entry.css';
import 'styles/components/link.css';
import './styles/nprogress.css';

window.global ||= window;

async function bootstrap() {
  // await applyPolyfills(); @TOOD(juan): remove this??

  render();
}

async function render() {
  // At this moment React hasn't initialized yet, so logged user
  // information is not available yet. LaunchDarkly will get flags for
  // the anonymous user. Later, AWSCognitoWrapper will set the user ID and
  // trigger a new fetch by LD.
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.get('launchDarklyClientId') ?? '',
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('app'),
  );
}

bootstrap();
