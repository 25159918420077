import React from 'react';
import { FullClient } from 'domains/client';
import { OnChangeActiveClient } from 'domains/auth/UserClientsWrapper/UserClientsWrapper';

/** Type when user is not logged in */
type LoggedOut = {
  isLoggedIn: false;
};

/** Common values when profile selected or not */
type LoggedInBase = {
  isLoggedIn: true;

  clients: FullClient[];
  contactId: string;

  changeClient: OnChangeActiveClient;
  signOut: () => void;
};

/** Type when a client (profile) is selected */
type ClientSelected = LoggedInBase & {
  isLoggedIn: true;
  isProfileSelected: true;
  activeClient: FullClient;
  activeClientId: string;
};

/** Type when a client (profile) is NOT selected. Either within the profile selection screen or some other page that doesn't require an active client */
type NoClientSelected = LoggedInBase & {
  isLoggedIn: true;
  isProfileSelected: false;
  activeClientId: null;
  activeClient: null;
};

export type UserClientsCtx = ClientSelected | NoClientSelected | LoggedOut;

export type UserClientsCtxWithLoggedIn = ClientSelected | NoClientSelected;

export type UserClientsCtxWithActiveClient = ClientSelected;

export const UserClientsContext = React.createContext<UserClientsCtx>({} as UserClientsCtx);
