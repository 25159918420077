import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const mainContentId = 'maincontent';

/**
 * @FIXME(shawk): The onCLick handler here is a workaround for an issue
 * with react-router.
 *
 * Without it, a new history entry is created upon navigating to the hash link
 * which also clears out the associated route entry state (thereby oblierating
 * e.g. flash messages, which we don't want to do simply when jumping down the
 * same page.)
 *
 * If RR ever better supports hash links, perhaps this issue can be resolved.
 */
export const SkipLinks = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <a
      href={`#${mainContentId}`}
      className="group sr-only focus:!not-sr-only"
      onClick={e => {
        e.preventDefault();
        const target = document.getElementById(mainContentId);
        target?.scrollIntoView({ behavior: 'smooth' });
        target?.focus(); // assumes target is focusable
      }}
    >
      <p
        className={cx(
          'absolute p-16 top-8 left-8 rounded-sm bg-white font-medium text-primary-500 outline-none ring-offset-0 transition-all',
          'group-focus:ring-primary-500/30 group-focus:ring-offset-2 group-focus:ring-offset-white group-focus:ring-2',
        )}
      >
        {t('a11y.skipToMainContent')}
      </p>
    </a>
  );
};
