import { ReverseParams } from 'named-urls';
import lazy, { PreloadableComponent } from 'react-lazy-with-preload';
import { QueryClient } from 'react-query';
import { NavigateOptions } from 'react-router-dom';
import { routes } from 'router/routes';

const Dashboard = lazy(() => import('pages/Dashboard'));
const IntakeChild = lazy(() => import('pages/IntakeChild'));
const IntakeAdult = lazy(() => import('pages/IntakeAdult'));
const IntakeCompleted = lazy(() => import('pages/IntakeCompleted'));
const EvaluationReport = lazy(() => import('pages/EvaluationReport'));
const SessionNotes = lazy(() => import('pages/SessionNotes'));
const CareHistory = lazy(() => import('pages/CareHistory'));
const ResourceLibrary = lazy(() => import('pages/ResourceLibrary'));
const Claim = lazy(() => import('pages/Claim'));
const RescheduleExpired = lazy(() => import('pages/RescheduleDirect/blocks/RescheduleExpired'));
const RescheduleAppointment = lazy(() => import('pages/RescheduleAppointment'));
const CancelAppointment = lazy(() => import('pages/CancelAppointment'));
const ChangeAppointment = lazy(() => import('pages/ChangeAppointment'));
const Assignment = lazy(() => import('pages/Assignment'));
const CompletedAssignment = lazy(() => import('pages/Assignment/completed'));
const HomePractice = lazy(() => import('pages/HomePractice'));
const Billing = lazy(() => import('pages/Billing'));
const ReleaseOfInformation = lazy(() => import('pages/ReleaseOfInformation'));
const FileUploadForm = lazy(() => import('pages/FileUploadForm'));

export type FetchDataFn = (
  queryClient: QueryClient,
  routeParams: ReverseParams | undefined,
  state: NavigateOptions['state'],
) => Promise<void | void[]>;

type WithFetchData<T> = T & { fetchData?: FetchDataFn };

export const asyncRouteMapping: Record<string, PreloadableComponent<WithFetchData<React.FC>>> = {
  [routes.root]: Dashboard,
  [routes.carePlan.evaluationReport]: EvaluationReport,
  [routes.session.detail]: SessionNotes,
  [routes.intake.child]: IntakeChild,
  [routes.intake.adult]: IntakeAdult,
  [routes.intake.completed]: IntakeCompleted,
  [routes.careHistory]: CareHistory,
  [routes.resourceLibrary]: ResourceLibrary,
  [routes.billing]: Billing,
  [routes.billingUpdatePaymentMethod]: Billing,
  [routes.claims]: Claim,
  [routes.rescheduleExpired]: RescheduleExpired,
  [routes.assignments.detail]: Assignment,
  [routes.assignments.completed]: CompletedAssignment,
  [routes.homePractice]: HomePractice,
  [routes.rescheduleAppointment]: RescheduleAppointment,
  [routes.cancelAppointment]: CancelAppointment,
  [routes.changeAppointment]: ChangeAppointment,
  [routes.releaseOfInformation]: ReleaseOfInformation,
  [routes.fileUploadForm]: FileUploadForm,
} as const;
