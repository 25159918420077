import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import { captureException } from '@sentry/react';
import { Button } from 'components/Button';
import { Heading, Paragraph } from 'components/Typography';
import { ExpressablesFooter } from 'components/Layout/AuthenticationLayout';
import { BasicHeader } from 'components/Layout/BasicHeader';
import { useCognitoUserContext } from 'domains/auth/AWSCognitoWrapper/context';

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error?: Error;
  resetErrorBoundary?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isLoggedIn } = useCognitoUserContext();

  useEffectOnce(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`App crashed`, error);
      captureException(error);
    }
  });

  return (
    <main id="maincontent" tabIndex={-1} className="flex flex-col flex-grow bg-cream-50">
      <BasicHeader showLogin={!isLoggedIn} />
      <div className="flex flex-col items-center max-w-[600px] m-auto pt-40 text-center">
        <Heading level="h2" as="h1" className="whitespace-pre-wrap">
          {t('appError.pageHeading')}
        </Heading>
        <Paragraph className="mt-8">{t('appError.helperText')}</Paragraph>

        <Button as="a" href="https://app.expressable.io/" className="mt-40" size="lg" onClick={resetErrorBoundary}>
          {t('appError.returnToAppButtonText')}
        </Button>
      </div>
      <ExpressablesFooter />
    </main>
  );
};
