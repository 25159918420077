import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { ErrorFallback } from './ErrorFallback';

export type Props = {
  children?: React.ReactNode;
  fallback?: React.ComponentType<FallbackProps>;
};

export const AppErrorBoundary = ({ children = <Outlet />, fallback = ErrorFallback }: Props): JSX.Element => {
  return <ErrorBoundary FallbackComponent={fallback}>{children}</ErrorBoundary>;
};
