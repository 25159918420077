import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import Logo from 'assets/expressable-logo.svg?react';
import { ButtonLink } from 'components/ButtonLink';
import { useNavigation } from 'utils/hooks/useNavigation';

import { HamburgerMenu } from 'components/Layout/AuthedLayout/AuthedHeader/NavigationBar/HamburgerMenu';
import { LanguageSwitcher } from 'components/LanguageSwitcher';

type Props = { className?: string; showLogin?: boolean; showLanguageSwitcher?: boolean };

export const BasicHeader: React.FC<Props> = ({ className, showLogin, showLanguageSwitcher = true }) => {
  const { routes } = useNavigation();

  return (
    <header
      className={twMerge('w-full flex bg-cream-50 py-20 px-24 min-h-[88px] border-b border-black-12%', className)}
    >
      <div className="w-full flex items-center">
        {showLanguageSwitcher && <HamburgerMenu className="mr-8" />}
        <Logo className={classNames('fill-current text-black-100% w-auto h-24')} title="Expressable" />
        {showLanguageSwitcher && <LanguageSwitcher className="ml-16 2xl-d:hidden" />}
      </div>

      {showLogin && (
        <div className="flex">
          <ButtonLink to={routes.root} size="md">
            Login
          </ButtonLink>
        </div>
      )}
    </header>
  );
};
