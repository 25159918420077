import cx from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { PolymorphicComponentProps } from 'types/component-types';

type TextProps = {
  className?: string;
  children?: React.ReactNode;
  gutterBottom?: boolean;
  noWrap?: boolean;
  useDefaultMargins?: boolean;
};

export type Props<C extends React.ElementType> = PolymorphicComponentProps<C, TextProps>;

export const Paragraph = <C extends React.ElementType = 'p'>({
  as,
  children,
  className,
  gutterBottom = true,
  noWrap = false,
  useDefaultMargins,
  ...props
}: Props<C>): JSX.Element => {
  return React.createElement(
    as || 'p',
    {
      className: twMerge(
        cx('text-sm md:text-md whitespace-pre-line', {
          'mb-[1rem] last:mb-0': gutterBottom,
          'whitespace-nowrap overflow-hidden text-ellipsis': noWrap,
          'mb-40': useDefaultMargins, // @TODO(juan): these are still not confirmed with kim
        }),
        className,
      ),
      ...props,
    },
    children,
  );
};
