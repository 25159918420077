import cx from 'classnames';
import { useNavigationType } from 'react-router-dom';
import { FlashMessage } from 'components/FlashMessages';
import { filterFlashMessages, useFlashMessages } from 'domains/flash-messages';
import { useFlashMessageScope } from 'domains/flash-messages/useFlashMessageScope';

type Props = {
  className?: string;
};

export const FlashWrapper = ({ className }: Props): JSX.Element | null => {
  const { flashMessages, clearFlash } = useFlashMessages();
  const navigationType = useNavigationType();
  const scope = useFlashMessageScope();
  const filteredFlashMessages = filterFlashMessages(scope, flashMessages);

  if (filteredFlashMessages.length && navigationType !== 'POP') {
    return (
      <div
        className={cx(className, 'space-y-16 sm:space-y-24', {
          // @NOTE(shawk) @COPY: these styles mirror those in <Container> with a different y-margin
          'container  px-20 md:px-32 md:max-w-3xl my-20': !className,
        })}
      >
        {filteredFlashMessages.map(({ id, variant, message }) => {
          return (
            <FlashMessage key={id} variant={variant} onDismiss={() => clearFlash([id])}>
              {message}
            </FlashMessage>
          );
        })}
      </div>
    );
  }

  return null;
};
