export type LanguageCode = 'en-US' | 'es-ES';

export type Language = {
  value: LanguageCode;
  label: string;
  abbreviation: string;
};

export const LANGUAGES: Language[] = [
  {
    value: 'en-US',
    abbreviation: 'EN',
    label: 'EN - English',
  },
  {
    value: 'es-ES',
    abbreviation: 'ES',
    label: 'ES - Español',
  },
];
