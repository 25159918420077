import type { SVGProps } from 'react';
const SvgQuiz = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_1547_34454)">
      <path d="M17 20H4V7C4 6.45 3.55 6 3 6C2.45 6 2 6.45 2 7V20C2 21.1 2.9 22 4 22H17C17.55 22 18 21.55 18 21C18 20.45 17.55 20 17 20Z" />
      <path d="M20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM14.01 15C13.42 15 12.96 14.53 12.96 13.95C12.96 13.36 13.43 12.91 14.01 12.91C14.6 12.91 15.05 13.36 15.05 13.95C15.04 14.53 14.6 15 14.01 15ZM16.51 8.83C15.88 9.76 15.28 10.04 14.95 10.64C14.87 10.78 14.82 10.9 14.79 11.13C14.74 11.52 14.43 11.81 14.04 11.81H14.01C13.57 11.81 13.22 11.43 13.26 10.99C13.29 10.71 13.35 10.42 13.51 10.15C13.92 9.42 14.69 8.99 15.14 8.35C15.62 7.67 15.35 6.41 14 6.41C13.39 6.41 12.99 6.73 12.74 7.11C12.55 7.4 12.17 7.5 11.85 7.36C11.43 7.18 11.25 6.66 11.51 6.29C12.02 5.55 12.87 5 13.99 5C15.22 5 16.07 5.56 16.5 6.26C16.87 6.87 17.08 7.99 16.51 8.83Z" />
    </g>
    <defs>
      <clipPath id="clip0_1547_34454">
        <rect width={24} height={24} />
      </clipPath>
    </defs>
  </svg>
);
export default SvgQuiz;
