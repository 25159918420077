import cx from 'classnames';
import type { ButtonProps } from 'components/Button';
import { BaseButton } from 'components/Button/Base';
import { Icon } from 'components/Icon';

export type Props = Omit<ButtonProps<'button'>, 'startAdornment' | 'endAdornment' | 'iconOnly'>;

export const CloseButton = ({ className, ...props }: ButtonProps<'button'>): JSX.Element => {
  const buttonClasses = cx(
    className,
    'border border-transparent focus-visible:ring-primary-500/30',
    'text-gray-700 font-medium bg-transparent',
    'ml-12',
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseButton {...props} iconOnly size="sm" className={buttonClasses}>
      <Icon name="Times" title="dismiss message" className="h-20 w-20 sm:h-24 sm:w-24" />
    </BaseButton>
  );
};
