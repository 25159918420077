import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import Menu from 'components/Menu';
import { LANGUAGES } from 'domains/language';

type Props = { className?: string; position?: 'left' | 'right' };

export const LanguageSwitcher: React.FC<Props> = ({ className, position = 'right' }) => {
  const {
    i18n: { language, changeLanguage },
    t,
  } = useTranslation();

  const onSwitchLanguage = (langId: string) => {
    changeLanguage(langId);
  };

  const currentLanguage = LANGUAGES.find(({ value }) => value === language);
  return (
    <Popover className={classNames('relative', className)}>
      {({ open }) => {
        return (
          <>
            <Popover.Button>
              <Button
                as="span"
                variant="secondary"
                size="sm"
                className={classNames({
                  'outline-2 outline-black-100% outline-offset-0': open,
                })}
                endAdornment={
                  <Icon
                    name="ChevronDown"
                    className={classNames('ml-8 w-18 transition-transform', {
                      'rotate-180': open,
                    })}
                  />
                }
              >
                {currentLanguage?.abbreviation}
              </Button>
            </Popover.Button>
            <Popover.Panel
              className={classNames('absolute z-50 min-w-[360px] mt-8', {
                'right-0': position === 'left',
              })}
            >
              <Menu>
                <Menu.SectionTitle>{t('language.sectionTitle')}</Menu.SectionTitle>
                <Menu.SelectSection
                  defaultValue={language}
                  onChange={(value: string) => {
                    onSwitchLanguage(value);
                  }}
                >
                  {LANGUAGES.map(({ value, label }) => (
                    <Menu.SelectItem value={value} key={value}>
                      {label}
                    </Menu.SelectItem>
                  ))}
                </Menu.SelectSection>
              </Menu>
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
};
