import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';
import config from 'config';

const env = config.get('env');
const isDev = env === 'development';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 1 DAY

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    debug: isDev,

    load: 'currentOnly',
    fallbackLng: 'en-US',
    keySeparator: false,
    nsSeparator: false,

    interpolation: {
      escapeValue: false,
    },

    // @NOTE(shawk): this prevents react-i18next from trying to issue an XHR for the translation JSON in tests
    ...(env === 'test' ? { resources: { en: { translations: {} } } } : {}),

    backend: {
      backends: [LocalStorageBackend, HttpBackend],

      backendOptions: [
        {
          prefix: 'expressable_client_portal_',
          defaultVersion: config.get('i18nCacheKey'),
          expirationTime: isDev ? 0 : CACHE_DURATION,
        },
        {
          loadPath: '/locales/catalogs/{{lng}}/translation.json',
        },
      ],
    },

    detection: {
      caches: ['cookie', 'localStorage'],
      lookupCookie: 'preferred_language',
      lookupLocalStorage: 'preferredLanguage',
    },

    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      if (isDev) {
        throw new Error(
          `No translation found for language "${lng}" for "${key}" in namespace "${ns}". Fallback is ${fallbackValue}.`,
        );
      }
    },
  });

i18n.on('languageChanged', lng => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
