import { Location, matchPath, NavigateOptions, Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import qs, { ParsedQuery } from 'query-string';
import { reverse, ReverseParams } from 'named-urls';
import { allRoutes, preload as preloadRoute, resolvedPathToPattern } from 'utils/routing';
import { routes } from 'router/routes';
import { createFlash, FlashMessageData } from 'domains/flash-messages';
import { start, stop } from 'domains/api/global-progress';

type QueryParam = number | string;
type Query = Record<string, QueryParam | QueryParam[]>;

export type LocationWithQuery<T> = Location & { state: T | null; query: ParsedQuery<string> };

export type UseNavigationValue<T> = {
  params: Readonly<Params<string>>;
  location: LocationWithQuery<T>;
  routes: typeof routes;
  preload: typeof preloadRoute;
  navigate: (
    to: string,
    options?: {
      params?: ReverseParams;
      preload?: boolean;
      query?: Query;
      flash?: FlashMessageData;
    } & NavigateOptions,
  ) => void;
};

export const useNavigation = <T extends Record<string, string | boolean | number>>(): UseNavigationValue<T> => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  return {
    params,
    location: { ...location, query: qs.parse(location.search, { arrayFormat: 'comma' }) } as LocationWithQuery<T>,
    routes,
    preload: preloadRoute,
    navigate: async (to, { params: nextRouteParams, query, preload, flash, ...options } = {}) => {
      const path = params ? reverse(to, nextRouteParams) : to;
      const matchedPath = allRoutes.map(route => matchPath(route, path)).find(route => !!route);

      if (!matchPath) {
        throw new Error('Invalid path passed to `navigate`!');
      }

      /**
       * When preloading, the AppLoader won't be displayed (since there is no
       * suspended component) so we have to trigger the progress indicator here.
       */
      if (preload) {
        start();

        await preloadRoute(resolvedPathToPattern(path), matchedPath?.params as ReverseParams, options.state);

        stop();
      }

      const destUrl = query ? `${to}?${qs.stringify(query)}` : to;

      navigate(destUrl, {
        ...options,
        state: { ...(options.state || {}), flash: flash ? [createFlash(flash.variant, flash.message)] : [] },
      });
    },
  };
};
